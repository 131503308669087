'use client';
import { LoginPayment, LoginProcess, LoginSupport } from '@/assets/lotties';
import useAuth from '@/contexts/AuthProvider';
import AroggaAPI from '@/services/ecommerce/apis/AroggaAPI';
import { AUTH } from '@/services/ecommerce/apis/apiConstrant';
import { partnerStorageName } from '@/services/ecommerce/apis/config';
import useTimer from '@/services/ecommerce/hooks/useTimer';
import { validPhoneNumber } from '@/services/ecommerce/utils/helper';
import cls from 'classnames';
import { isEmpty } from 'lodash';
import { useRouter } from 'next/navigation';
import React, { useEffect, useRef, useState } from 'react';
import { MdArrowDropDown, MdArrowRight, MdOutlineClose } from 'react-icons/md';
import { RiEdit2Fill } from 'react-icons/ri';
import Alert from '../atoms/Alert';
import Button from '../atoms/Button';
import Input from '../atoms/Input';
import LottiePlayer from '../atoms/LottiePlayer/LottiePlayer';
import Modal from '../atoms/Modal';
import SpinnerWrapper from '../atoms/SpinnerWrapper';
import SocialLogin from './SocialLogin';
import styles from './styles/login.module.scss';

const slider = [
	{
		Image: LoginProcess,
		title: 'Quick & easy ordering process',
		description: 'Now you can order your medicine from Arogga. We provide all the medicines you need.'
	},
	{
		Image: LoginSupport,
		title: 'Instant support & reply',
		description:
			'Arogga will receive your order and be able to reply to you once you place an order and ask for help.'
	},
	{
		Image: LoginPayment,
		title: 'Easy & multi-payment solutions',
		description: 'You can pay in cash. Or online using your usual methods.'
	}
];

interface LoginProps {
	showLoginModal: boolean;
	setShowLoginModal: React.Dispatch<React.SetStateAction<boolean>>;
	thirdPartyRef: any;
	setThirdPartyRef: React.Dispatch<React.SetStateAction<any>>;
	goTo?: string | null;
}

const Login = ({ showLoginModal, setShowLoginModal, thirdPartyRef, setThirdPartyRef, goTo }: LoginProps) => {
	const [curIndex, setCurIndex] = useState<number>(0);
	const statusRef = useRef<HTMLDivElement | null>(null);
	const [resetBtn, setResetBtn] = useState<boolean>(false);
	const [verifyBtn, setVerifyBtn] = useState<boolean>(false);
	const [statusInfo, setStatusInfo] = useState<{
		status: 'success' | 'error' | 'warning';
		message: string;
	}>({ status: 'success', message: '' });
	const [newUser, setNewUser] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [phoneEdit, setPhoneEdit] = useState<boolean>(false);
	const [mobileChangable, setMobileChangable] = useState<boolean>(false);
	const [preventClose, setPreventClose] = useState<boolean>(false);
	const timerOnFinished = () => {
		setResetBtn(true);
		setStatusInfo({
			status: 'success',
			message: ''
		});
	};
	const otpInputRef = useRef<HTMLInputElement | null>(null);
	const { minutes, seconds, startTimer, stopTimer, isActive, resetTimer } = useTimer(30, timerOnFinished);
	const router = useRouter();
	const { userLoginAction, user } = useAuth();
	const [value, setValue] = useState<{
		phone: string;
		otp: string;
		refCode: string;
	}>({
		phone: '',
		otp: '',
		refCode: ''
	});

	const closeModal = () => {
		setShowLoginModal(false);
	};
	function formatBDPhoneNumber(phoneNumber) {
		phoneNumber = phoneNumber?.replace(/\D/g, '');
		// Remove any existing country code or leading zeroes
		phoneNumber = phoneNumber?.replace(/^\+88/, '')?.replace(/^88/, '')?.replace(/^0/, '');

		// Check if the number is of the correct length (10 digits after removing country code)
		if (phoneNumber.length !== 10) {
			return '';
		}

		// Add country code +880 in front of the number
		return '0' + phoneNumber;
	}

	const handleChange = (name: keyof typeof value) => (e: React.ChangeEvent<HTMLInputElement>) => {
		if (name === 'phone') {
			if (isNaN(Number(e.target.value))) {
				return;
			}
			let newValue = e.target.value;

			// Remove the '88' or '+88' prefix if present
			if (newValue.startsWith('+88')) {
				newValue = newValue.substring(3);
			} else if (newValue.startsWith('88')) {
				newValue = newValue.substring(2);
			}

			// Limit the length to 14 characters
			if (newValue.length > 11) {
				newValue = newValue.slice(0, 11);
			}

			setValue({ ...value, [name]: newValue });
		} else {
			setValue({ ...value, [name]: e.target.value });
		}

		setStatusInfo({
			status: 'success',
			message: ''
		});
	};

	const handleAuthSms = async (phone: string | null = null) => {
		const phone_number = phone || value.phone;
		const validNumber = validPhoneNumber(phone_number);

		if (validNumber) {
			setLoading(true);
			setPhoneEdit(true);
			const res = await AroggaAPI(AUTH.SENDSMS, {
				method: 'POST',
				body: {
					mobile: phone_number,
					fcmToken: '',
					referral: value.refCode
					// fcm_token: fcmToken,
				}
			});
			if (res.status === 'fail') {
				setStatusInfo({
					status: 'error',
					message: res.message
				});
				setPhoneEdit(false);
			}
			if (res.status === 'success') {
				setVerifyBtn(true);
				startTimer();
				value.refCode ? setNewUser(true) : setNewUser(false);
				setStatusInfo({
					status: 'success',
					message: res.message
				});
			}
			setLoading(false);
		} else {
			setStatusInfo({
				status: 'warning',
				message: 'Please enter valid phone number.'
			});
			setPhoneEdit(false);
		}
	};

	const handleLogin = async () => {
		if (!value.otp) {
			setStatusInfo({
				status: 'warning',
				message: 'Please enter OTP number.'
			});
			return;
		}

		setLoading(true);

		const res = await AroggaAPI(AUTH.SMS_VERIFY, {
			method: 'POST',
			body: {
				mobile: value.phone,
				otp: value.otp,
				referral: value.refCode
				// fcm_token: fcmToken,
			}
		});

		if (res.status === 'success') {
			setLoading(false);
			userLoginAction(res.data);
			setShowLoginModal(false);
			setStatusInfo({
				status: 'success',
				message: res.message
			});
			if (!isEmpty(goTo)) {
				router.push(goTo);
			}
		}

		if (res.status === 'fail') {
			setLoading(false);
			setStatusInfo({
				status: 'error',
				message: res.message
			});
		}
	};
	const handleResend = () => {
		setResetBtn(false);
		resetTimer();
		startTimer();
		setValue({
			...value,
			otp: ''
		});
		handleAuthSms();
	};

	useEffect(() => {
		if (!showLoginModal) {
			setCurIndex(0);
			setValue({
				phone: '',
				otp: '',
				refCode: ''
			});
			setResetBtn(false);
			setVerifyBtn(false);
			setStatusInfo({
				status: 'success',
				message: ''
			});
			setLoading(false);
			setPhoneEdit(false);
			setNewUser(false);
			stopTimer();
		}
		if (showLoginModal) {
			const localUser = JSON.parse(localStorage.getItem(partnerStorageName) || '{}');
			if (!isEmpty(user)) {
				const { mobile, mobileChangable } = localUser;

				const IsValid = validPhoneNumber(mobile);
				if (IsValid && mobile) {
					const formatMobile = formatBDPhoneNumber(mobile);
					setValue({
						phone: formatMobile,
						otp: '',
						refCode: ''
					});
					if (mobileChangable && mobileChangable === 'yes') {
						setMobileChangable(true);
						setPhoneEdit(false);
					} else {
						setMobileChangable(false);
						setPhoneEdit(true);
					}
				} else {
					setValue({
						phone: '',
						otp: '',
						refCode: ''
					});
					setPhoneEdit(true);
					setMobileChangable(true);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showLoginModal]);

	useEffect(() => {
		if (thirdPartyRef) {
			const { showLogin, autoLogin, initialOTPSend, mobile, mobileChangable, token } = thirdPartyRef;
			const IsValid = validPhoneNumber(mobile);

			if (autoLogin && autoLogin === 'yes' && !!mobile && !!token) {
				AroggaAPI.post('/v1/auth/auto-login', {
					mobile,
					token
				})
					.then(async (userRes) => {
						if (userRes.status == 'success') {
							setThirdPartyRef(null);
							// dispatch(userLoginAction(userRes.data.user));
							const localthirdPartyRef = thirdPartyRef;
							if (localthirdPartyRef) {
								const { refPartner, appNotifyBanner } = localthirdPartyRef;
								localStorage.setItem(
									partnerStorageName,
									JSON.stringify({
										refPartner,
										appNotifyBanner
									})
								);
							}
						}
					})
					.catch((error) => {
						const localthirdPartyRef = thirdPartyRef;
						if (localthirdPartyRef) {
							const { refPartner, appNotifyBanner, mobile, mobileChangable } = localthirdPartyRef;
							localStorage.setItem(
								partnerStorageName,
								JSON.stringify({
									refPartner,
									appNotifyBanner,
									mobile,
									mobileChangable
								})
							);
						}
					});
			} else if (IsValid && showLogin && showLogin === 'yes' && mobile) {
				const formatMobile = formatBDPhoneNumber(mobile);
				setValue({
					phone: formatMobile,
					otp: '',
					refCode: ''
				});
				setShowLoginModal(true);
				setPreventClose(true);
				initialOTPSend && initialOTPSend === 'yes' && handleAuthSms(formatMobile);
				if (mobileChangable && mobileChangable === 'yes') {
					setMobileChangable(true);
					setPhoneEdit(false);
				} else {
					formatMobile && setMobileChangable(false);
					formatMobile && setPhoneEdit(true);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [thirdPartyRef]);

	useEffect(() => {
		//set index chenge after 5 sec
		const interval = setInterval(() => {
			if (curIndex < slider.length - 1) {
				setCurIndex(curIndex + 1);
			} else {
				setCurIndex(0);
			}
		}, 5000);

		return () => clearInterval(interval);
	}, [curIndex]);
	useEffect(() => {
		const inputid = document.getElementById('phone');
		if (inputid) {
			inputid.focus();
		}
	}, [showLoginModal]);

	useEffect(() => {
		if (otpInputRef.current) {
			otpInputRef.current.focus();
		}
	}, [verifyBtn]);
	return (
		<div>
			<Modal
				isOpen={showLoginModal}
				onClose={closeModal}
				footer={null}
				maskClosable={!preventClose}
				closeIcon={true}
				width={800}>
				<div className={styles.model_container}>
					<div className={styles.image_container}>
						<div className={styles.image_content}>
							<div>
								<LottiePlayer
									animationData={slider[curIndex].Image}
									loop={true}
									autoplay={true}
									height='200px'
								/>
							</div>
							<div className={styles.image_title}>{slider[curIndex].title}</div>
							<div className={styles.image_description}>{slider[curIndex].description}</div>
							<div className={styles.image_dots}>
								{Array(slider.length)
									.fill(0)
									.map((e, i) => (
										<div
											key={i}
											onClick={() => {
												resetTimer();
												setCurIndex(i);
											}}
											className={styles.dot}
											style={{
												width: i == curIndex ? 20 : 8,
												backgroundColor: i == curIndex ? '#0E7673' : '#B2BAC6',
												borderRadius: i == curIndex ? 16 : '50%'
											}}></div>
									))}
							</div>
						</div>
					</div>
					<SpinnerWrapper loading={loading}>
						<div className={styles.login_container}>
							<div className={styles.login_content}>
								<MdOutlineClose
									style={{
										cursor: 'pointer',
										position: 'absolute',
										top: 10,
										right: 10
									}}
									onClick={() => {
										setShowLoginModal(false);
									}}
									size={24}
								/>
								<div className={styles.login_title}>Login</div>
								<div className={styles.login_subtitle}>
									Login to make an order, access your orders, special offers, health tips, and more!
								</div>
								<label className='text-grey900 fw-500'>Phone Number</label>
								<div style={{ position: 'relative' }}>
									<div className={cls(styles.absulate_prefix, phoneEdit && styles.disabled)}>
										<span className={cls(styles.prefix, phoneEdit && styles.disabled)}>+88</span>
									</div>
									<Input
										id='phone'
										style={{
											paddingLeft: 45,
											height: 50
										}}
										className={cls(styles.input_phone)}
										type='text'
										defaultValue=''
										value={value.phone}
										disabled={phoneEdit}
										onChange={handleChange('phone')}
										placeholder=''
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												handleAuthSms();
											}
										}}
									/>
									{phoneEdit && (
										<div
											style={{
												position: 'absolute',
												right: 10,
												top: '14%',
												color: '#0E7673'
											}}>
											<div
												style={{
													height: 34,
													width: 34,
													borderRadius: '50%',
													backgroundColor: '#fff',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center'
												}}>
												<RiEdit2Fill
													onClick={() => {
														setPhoneEdit(false);
														setVerifyBtn(false);
														setStatusInfo({
															status: 'success',
															message: ''
														});
														setValue({
															...value,
															otp: ''
														});
														setResetBtn(false);
														resetTimer();
													}}
													style={{
														cursor: 'pointer',
														color: '#0E7673',
														fontSize: 20
													}}
												/>
											</div>
										</div>
									)}
								</div>
								{!!verifyBtn && (
									<Input
										ref={otpInputRef}
										id='otp_input'
										type='number'
										value={value.otp}
										placeholder='Enter your OTP number'
										onChange={handleChange('otp')}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												handleLogin();
											}
										}}
									/>
								)}

								<div>
									<span
										style={{
											cursor: 'pointer',
											textDecoration: 'underline',
											display: 'flex',
											justifyContent: 'space-between',
											paddingBlock: 10,
											marginLeft: 2
										}}
										className='text-primary fw-400'
										onClick={() => {
											if (!!value.refCode && newUser) {
												setNewUser(true);
											} else {
												setNewUser(!newUser);
											}
										}}>
										Have a referral code?
										<span
											style={{
												color: '#0E7673'
											}}>
											{' '}
											{newUser ? <MdArrowDropDown size={24} /> : <MdArrowRight size={24} />}
										</span>
									</span>
									{newUser && (
										<Input
											style={{
												height: 50
											}}
											type='text'
											value={value.refCode}
											placeholder='Enter your referral code'
											onChange={handleChange('refCode')}
										/>
									)}
								</div>

								<div style={{ marginTop: 0 }}>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: 20
										}}>
										{verifyBtn ? (
											<div className='d-flex justify-content-between w-full gap-5'>
												<div
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														width: '100%',
														marginLeft: 'auto'
													}}>
													{!!isActive && (
														<div
															className='bg-grey900 text-white d-flex items-center'
															style={{
																padding: '0 10px',
																borderRadius: 5,
																width: '40%',
																justifyContent: 'center'
															}}>
															<span>{minutes}</span>
															<span>:</span>
															<span>{seconds}</span>
														</div>
													)}
													{!!resetBtn && (
														<Button
															size='large'
															type='primary'
															className='h-44'
															onClick={handleResend}
															label={resetBtn ? 'Resend' : 'Verify'}
															style={{
																backgroundColor: '#000',
																color: 'white',
																border: 'none',
																width: '40%'
															}}
														/>
													)}
													<Button
														style={{
															marginRight: 10
														}}
														className='h-44'
														size='large'
														label={verifyBtn ? 'Login' : 'Send'}
														type='primary'
														onClick={handleLogin}
													/>
												</div>
											</div>
										) : (
											<Button
												style={{
													minWidth: '100%'
												}}
												size='large'
												type='primary'
												label={verifyBtn ? 'Verify' : 'Send'}
												onClick={() => handleAuthSms()}
											/>
										)}
									</div>
									{!!statusInfo && !!statusInfo.status && !!statusInfo.message && (
										<div ref={statusRef} style={{ margin: '10px 0' }}>
											<Alert type={statusInfo.status} message={statusInfo.message} />
										</div>
									)}

									<SocialLogin />

									<p
										className='text-grey600'
										style={{
											paddingTop: 20,
											fontSize: 14,
											lineHeight: '160%'
										}}>
										By continuing you agree to{' '}
										<a href='/page/tos' target='_blank' className='text-primary  fw-500'>
											Terms &amp; Conditions
										</a>
										{', '}
										<a href='/page/privacy' target='_blank' className='text-primary fw-500'>
											Privacy Policy
										</a>{' '}
										&amp;{' '}
										<a href='/page/return-policy' target='_blank' className='text-primary fw-500'>
											Refund-Return Policy
										</a>
									</p>
								</div>
							</div>
						</div>
					</SpinnerWrapper>
				</div>
			</Modal>
		</div>
	);
};

export default Login;
